@font-face {
  font-family: 'Calibri';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/calibri.eot');
  src:
    url('../fonts/Calibri.woff') format('woff'),
    url('../fonts/calibri.woff2') format('woff2'),
    url('../fonts/Calibri.ttf') format('truetype'),
    url('../fonts/Calibri.svg#Calibri') format('svg'),
    url('../fonts/calibri.eot?#iefix') format('embedded-opentype');
}
