/* todo:добавить все шрифты сюда, чтобы потом их грузить с помощью скрипта */
@import 'font_calibri';

@font-face {
  font-family: 'AvantGardeCTT';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/avantgardectt-bold.eot');
  src:
    url('../fonts/avantgardectt-bold.woff2') format('woff2'),
    url('../fonts/AvantGardeCTT-Bold.woff') format('woff'),
    url('../fonts/AvantGardeCTT-Bold.ttf') format('truetype'),
    url('../fonts/AvantGardeCTT-Bold.svg#AvantGardeCTT-Bold') format('svg'),
    url('../fonts/avantgardectt-bold.eot?#iefix') format('embedded-opentype');
}

@font-face {
  font-family: 'AvantGardeCTT';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/avantgardectt-bolditalic.eot');
  src:
    url('../fonts/avantgardectt-bolditalic.woff2') format('woff2'),
    url('../fonts/AvantGardeCTT-BoldItalic.woff') format('woff'),
    url('../fonts/AvantGardeCTT-BoldItalic.ttf') format('truetype'),
    url('../fonts/AvantGardeCTT-BoldItalic.svg#AvantGardeCTT-BoldItalic') format('svg'),
    url('../fonts/avantgardectt-bolditalic.eot?#iefix') format('embedded-opentype');
}

@font-face {
  font-family: 'AvantGardeCTT';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/avantgardectt-italic.eot');
  src:
    url('../fonts/avantgardectt-italic.woff2') format('woff2'),
    url('../fonts/AvantGardeCTT-Italic.woff') format('woff'),
    url('../fonts/AvantGardeCTT-Italic.ttf') format('truetype'),
    url('../fonts/AvantGardeCTT-Italic.svg#AvantGardeCTT-Italic') format('svg'),
    url('../fonts/avantgardectt-italic.eot?#iefix') format('embedded-opentype');
}

@font-face {
  font-family: 'AvantGardeCTT';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/avantgardectt.eot');
  src:
    url('../fonts/AvantGardeCTT.woff') format('woff'),
    url('../fonts/avantgardectt.woff2') format('woff2'),
    url('../fonts/AvantGardeCTT.ttf') format('truetype'),
    url('../fonts/AvantGardeCTT.svg#AvantGardeCTT') format('svg'),
    url('../fonts/avantgardectt.eot?#iefix') format('embedded-opentype');
}
@font-face {
  font-family: 'TruthCYR';
  src: url('../fonts/TruthCYR-Ultra.eot');
  src: url('../fonts/TruthCYR-Ultra.woff2') format('woff2'),
  url('../fonts/TruthCYR-Ultra.woff') format('woff'),
  url('../fonts/TruthCYR-Ultra.ttf') format('truetype'),
  url('../fonts/TruthCYR-Ultra.svg#TruthCYR-Ultra') format('svg'),
  url('../fonts/TruthCYR-Ultra.eot?#iefix') format('embedded-opentype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'TruthCYR';
  src: url('../fonts/TruthCYR-Medium.eot');
  src: url('../fonts/TruthCYR-Medium.woff2') format('woff2'),
  url('../fonts/TruthCYR-Medium.woff') format('woff'),
  url('../fonts/TruthCYR-Medium.ttf') format('truetype'),
  url('../fonts/TruthCYR-Medium.svg#TruthCYR-Medium') format('svg'),
  url('../fonts/TruthCYR-Medium.eot?#iefix') format('embedded-opentype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'TruthCYR';
  src: url('../fonts/TruthCYR-Black.eot');
  src: url('../fonts/TruthCYR-Black.woff2') format('woff2'),
  url('../fonts/TruthCYR-Black.woff') format('woff'),
  url('../fonts/TruthCYR-Black.ttf') format('truetype'),
  url('../fonts/TruthCYR-Black.svg#TruthCYR-Black') format('svg'),
  url('../fonts/TruthCYR-Black.eot?#iefix') format('embedded-opentype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'TruthCYR';
  src: url('../fonts/TruthCYR-Bold.eot');
  src: url('../fonts/TruthCYR-Bold.woff2') format('woff2'),
  url('../fonts/TruthCYR-Bold.woff') format('woff'),
  url('../fonts/TruthCYR-Bold.ttf') format('truetype'),
  url('../fonts/TruthCYR-Bold.svg#TruthCYR-Bold') format('svg'),
  url('../fonts/TruthCYR-Bold.eot?#iefix') format('embedded-opentype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'TruthCYR';
  src: url('../fonts/TruthCYR-Light.eot');
  src: url('../fonts/TruthCYR-Light.woff2') format('woff2'),
  url('../fonts/TruthCYR-Light.woff') format('woff'),
  url('../fonts/TruthCYR-Light.ttf') format('truetype'),
  url('../fonts/TruthCYR-Light.svg#TruthCYR-Light') format('svg'),
  url('../fonts/TruthCYR-Light.eot?#iefix') format('embedded-opentype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'TruthCYR';
  src: url('../fonts/TruthCYR-Regular.eot');
  src: url('../fonts/TruthCYR-Regular.woff2') format('woff2'),
  url('../fonts/TruthCYR-Regular.woff') format('woff'),
  url('../fonts/TruthCYR-Regular.ttf') format('truetype'),
  url('../fonts/TruthCYR-Regular.svg#TruthCYR-Regular') format('svg'),
  url('../fonts/TruthCYR-Regular.eot?#iefix') format('embedded-opentype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'TruthCYR';
  src: url('../fonts/TruthCYR-Thin.eot');
  src: url('../fonts/TruthCYR-Thin.woff2') format('woff2'),
  url('../fonts/TruthCYR-Thin.woff') format('woff'),
  url('../fonts/TruthCYR-Thin.ttf') format('truetype'),
  url('../fonts/TruthCYR-Thin.svg#TruthCYR-Thin') format('svg'),
  url('../fonts/TruthCYR-Thin.eot?#iefix') format('embedded-opentype');
  font-weight: 200;
  font-style: normal;
}







